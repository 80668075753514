const videoElements = document.querySelectorAll('.i-lazy-video');

[...videoElements].forEach((videoElement) => {
    const { videoId } = videoElement.dataset;

    videoElement.addEventListener('click', () => {
        const iframe = document.createElement('iframe');

        iframe.classList.add('video__iframe');
        iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`);
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('webkitallowfullscreen', '');
        iframe.setAttribute('mozallowfullscreen', '');

        videoElement.appendChild(iframe);

        iframe.addEventListener('load', () => {
            iframe.style.display = 'block';
        });
    });
});
