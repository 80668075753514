import jump from 'jump.js';

/**
 * scroller - triggers a scroll animation on the window to a specific element in the DOM tree.
 */
export default function scroller() {
    const target = document.querySelector(`[data-scroll-target="${this.getAttribute('data-scroll-to')}"]`);

    jump(target, {
        duration: 1000,
        offset: 0,
        a11y: false,
    });
}

[...document.querySelectorAll('[data-scroll-to]')].forEach((elm) => {
    elm.addEventListener('click', scroller);
});
