import pageTracking from '~@tracking/page';
import ContentReveal from '~@componentJs/content-reveal';

const category = 'trade landing - page';

/**
 * @param {string} selector
 * @param {string} action
 * @param {string} event
 */
function addTracking({
    selector = '',
    action = '',
    event = 'click',
}) {
    const elements = [...document.querySelectorAll(selector)];

    if (elements.length > 0) {
        elements.forEach(element => {
            const label = element.dataset.label || '';

            element.addEventListener(event, () => pageTracking.trackEvent({ category, action, label }));
        });
    }
}

const trackedElements = [
    {
        selector: '.i-trade-banner-create-account',
        action: 'banner - CTA',
    },
    {
        selector: '.i-trade-banner-sign-in',
        action: 'banner - CTA',
    },
    {
        selector: '.i-trade-loyalty-benefit',
        action: 'trade benefit - CTA',
    },
    {
        selector: '.i-quality-product-create-account',
        action: 'quality product - CTA',
    },
];

trackedElements.forEach(addTracking);

const containers = [...document.querySelectorAll('.loyalty-discounts__benefits')];

containers.forEach((container) => {
    const triggers = [...container.querySelectorAll('.i-toggle-active')];
    const panel = new ContentReveal(container, triggers, false);

    panel.onBeforeOpen(() => {
        panel.expand();
    });

    panel.onBeforeClose(() => panel.collapse());
});
