import debounce from 'lodash/debounce';

/**
 * @class Readmore
 */
export default class Readmore {
    /**
     * Set up the elements and bindings
     *
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.element = element;
        this.content = element.querySelector('.i-read-more-content');
        this.trigger = element.querySelector('.i-read-more-trigger');
        this.hidden = element.classList.contains('is-collapsed');
        this.setTrackingLabel(this.hidden);

        this.trigger.addEventListener('click', () => {
            this.handleClick();
        });

        window.addEventListener('resize', debounce(() => {
            this.updateView();
        }, 100));

        window.addEventListener('product-list-updated', () => {
            this.updateView();
        });

        this.updateView();
    }

    /**
     * work out the hidden/unhidden heights and make sure the correct
     * view is set after.
     *
     * @returns {{unhidden: number, hidden: number}}
     */
    heights() {
        this.element.classList.remove('is-collapsed');
        const unhidden = this.content.offsetHeight;
        this.element.classList.add('is-collapsed');
        const hidden = this.content.offsetHeight;

        if (!this.hidden) {
            this.element.classList.remove('is-collapsed');
        }

        return {
            unhidden,
            hidden,
        };
    }

    setTrackingLabel() {
        this.trigger.dataset.trackingLabel = this.hidden ? 'closed' : 'opened';
    }

    setText() {
        this.trigger.innerText = this.hidden ? 'Read more' : 'Read less';
    }

    /**
     * Handle toggling the read more/less button text
     * and the state.
     */
    handleClick() {
        this.element.classList.toggle('is-collapsed');
        this.hidden = !this.hidden;
        this.setTrackingLabel();
        this.setText();
    }

    /**
     * Handles the current view after a resize event
     * so the read more works responsively
     */
    updateView() {
        const {
            unhidden,
            hidden,
        } = this.heights();

        if (unhidden > hidden) {
            this.trigger.classList.add('is-visible');
            this.element.classList.add('will-show-more');
        } else {
            this.trigger.classList.remove('is-visible');
            this.element.classList.remove('will-show-more');
        }
    }
}
