import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} asset
     * @param {number} assetIndex
     */
    marketingAsset(asset, assetIndex) {
        dl.addTrackingEvent({
            category: 'home page',
            action: 'marketing assets',
            label: asset,
            value: assetIndex,
        });
    },
};
