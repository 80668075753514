import Countdown from './countdown';

const countdownTimer = new Countdown();

/**
 * showCountdown
 * Media carousel countdown is initially hidden until countdown is ready
 * to hide the placeholder 00d 00h 00m 00s
 *
 * @param {HTMLElement} countdown
 */
function initialise(countdown) {
    countdown.classList.remove('is-invisible');
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.i-countdown')].forEach((countdown) => {
        /**
         * render - render updated time as string e.g. 03d 02h 23m 00s
         *
         * @param {string} days
         * @param {string} hours
         * @param {string} minutes
         * @param {string} seconds
         */
        function render({
            days,
            hours,
            minutes,
            seconds,
        } = {}) {
            countdown.querySelector('.i-countdown-days').textContent = days;
            countdown.querySelector('.i-countdown-hours').textContent = hours;
            countdown.querySelector('.i-countdown-minutes').textContent = minutes;
            countdown.querySelector('.i-countdown-seconds').textContent = seconds;
        }

        countdownTimer.start(countdown.getAttribute('data-countdownend'), render);
        initialise(countdown);
    });
});
