import Tab from './tab';

/**
 * Creates tab components
 *
 * @param {HTMLElement} tabGroup
 * @returns {Tab}
 */
export default function createTabs(tabGroup) {
    const allTabs = tabGroup.querySelectorAll('.i-tab');
    const activeTab = tabGroup.querySelector('.i-tab.is-active');
    const tabList = tabGroup.querySelector('.tabs__list');
    const marker = tabGroup.querySelector('.tabs__marker');
    const allTabPanels = tabGroup.querySelectorAll('.i-tab-body');

    return new Tab(tabGroup, allTabs, allTabPanels, activeTab, tabList, marker);
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.tabs')].forEach((tabGroup) => {
        createTabs(tabGroup);
    });
});
