import { openVideo, openYouTubeVideo } from './photoswipe-setup';

[...document.querySelectorAll('.i-launch-video')].forEach((element) => {
    element.addEventListener('click', () => {
        if (element.dataset.youtubeVideo) {
            openYouTubeVideo(element, element.dataset.youtubeVideo);
        } else {
            openVideo(element, element.dataset.video);
        }
    });
});
