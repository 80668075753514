import keyCode from '../helpers/keycode';

/**
 * Maps tabs to the related content
 *
 * @param {object} tabs
 * @param {object} contents
 *
 * @returns {*}
 */
function mapContents(tabs, contents) {
    const mapped = new WeakMap();
    [...tabs].forEach((tab) => {
        const index = [...tabs].indexOf(tab);
        mapped.set(tab, {
            tab,
            index,
            content: [...contents][index],
        });
    });

    return mapped;
}

/**
 * @class Tab
 *
 * Creates tabs that toggle related content on click
 * On click a marker slides from the current active tab to the new active tab
 *
 */
export default class Tab {
    /**
     * @param {HTMLElement} tabGroup
     * @param {Array} allTabs
     * @param {object} allTabPanels
     * @param {HTMLElement} activeTab
     * @param {HTMLElement} tabList
     * @param {HTMLElement} marker
     */
    constructor(tabGroup, allTabs, allTabPanels, activeTab, tabList, marker) {
        this.tabGroup = tabGroup;
        this.allTabs = allTabs;
        this.activeTab = activeTab;
        this.tabList = tabList;
        this.marker = marker;

        this.initControls(allTabs);

        this.mappedTabs = mapContents(allTabs, allTabPanels);

        if (this.marker) {
            this.marker.addEventListener('transitionend', () => {
                this.marker.classList.remove('is-active');
            });
        }
    }

    /**
     * Sets up event listeners for click and pressing enter on a tab
     *
     * @param {object} allTabs
     */
    initControls(allTabs) {
        [...allTabs].forEach((tab) => {
            tab.addEventListener('click', e => this.handleTabs(e));
            tab.addEventListener('keypress', (e) => {
                const key = e.which || e.keyCode;
                if (key === keyCode.enter) {
                    this.handleTabs(e);
                }
            });
        });
    }

    /**
     * @param {event} e
     */
    handleTabs(e) {
        const tab = e.currentTarget;
        const previousTab = this.activeTab;

        this.closeTab(previousTab);
        this.openTab(tab);
    }

    /**
     * @param {HTMLElement} tab
     */
    openTab(tab) {
        const { content } = this.mappedTabs.get(tab);

        tab.classList.add('is-active');
        content.classList.add('is-active');
        content.setAttribute('aria-hidden', false);
        this.activeTab = tab;
        this.setAriaLabel('true');
    }

    /**
     * @param {HTMLElement} tab
     */
    closeTab(tab) {
        this.setAriaLabel('false');
        tab.classList.remove('is-active');
        this.mappedTabs.get(tab).content.classList.remove('is-active');
        this.mappedTabs.get(tab).content.setAttribute('aria-hidden', true);
    }

    /**
     * @param {string} value
     */
    setAriaLabel(value) {
        const link = this.activeTab.querySelector('.i-tab-link');

        if (link) {
            link.setAttribute('aria-selected', value);
        }
    }
}
