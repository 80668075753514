import pageTracking from '~@tracking/page';

const category = 'returns';
const action = 'policy page';
const contentLinks = document.querySelectorAll('.i-track-content-links');
const createReturn = document.querySelector('.i-track-create-return');

[...contentLinks].forEach((link) => {
    const label = link.innerText;

    if (label) {
        link.addEventListener('click', () => {
            pageTracking.trackEvent({
                category,
                action,
                label,
            });
        });
    }
});

if (createReturn) {
    createReturn.addEventListener('click', () => {
        pageTracking.trackEvent({
            category,
            action,
            label: 'create returns - cta clicked',
        });
    });
}
