import initialiseCarousel from '~@componentJs/image-carousel';
import loadBundle from '~@helpers/dynamic-bundles';

/**
 * openGallery initialises a new PhotoSwipe Gallery
 *
 * @param {object} gallery
 * @param {Array.<{src: string, w: number, h: number, msrc: string}>} items
 */
async function openGallery(gallery, items) {
    const pswpElement = document.querySelector('.pswp');

    if (pswpElement) {
        try {
            const { default: PhotoSwipe } = await loadBundle('photoswipe');
            const { default: PhotoSwipeUIDefault } = await loadBundle('photoswipe-ui');

            const largeGallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, {
                getThumbBoundsFn(index) {
                    const thumbnail = document.querySelectorAll('.image-carousel__slide')[index];
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = thumbnail.getBoundingClientRect();
                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                },
                showHideOpacity: true,
                bgOpacity: 0.9,
                index: gallery.getPos(),
                closeOnScroll: false,
            });
            largeGallery.init();

            largeGallery.listen('afterChange', () => {
                const index = largeGallery.getCurrentIndex();
                gallery.slide(index, 500);
            });
        } catch (e) {
            return Promise.reject(new Error('failed to open gallery'));
        }
    }

    return Promise.resolve();
}

/**
 * toggleFullscreenButton checks if the Fullscreen viewer button should be visible or not
 *
 * @param {number} index
 */
function toggleFullscreenButton(index) {
    const fullscreenBtn = document.querySelector('.i-image-gallery-fullscreen');

    if (fullscreenBtn.getAttribute('data-hide').includes(index.toString())) {
        fullscreenBtn.classList.add('hidden');
    } else {
        fullscreenBtn.classList.remove('hidden');
    }
}

/**
 * imageGalleryCallbacks calls the other functions required during a callback
 *
 * @param {number} index
 */
function imageGalleryCallbacks(index) {
    toggleFullscreenButton(index);
}

/**
 * initialiseImageGallery initialises a new SwipeJS Carousel
 *
 * @param {HTMLElement} carousel
 */
export default function initialiseImageGallery(carousel) {
    window.gallery = initialiseCarousel(carousel, {
        startDisabled: true,
    }, imageGalleryCallbacks);

    const items = JSON.parse(document.getElementById('gallery-json').innerHTML);

    [...document.querySelectorAll('.i-image-gallery-fullscreen')].forEach((element) => {
        element.addEventListener('click', () => {
            element.classList.add('is-loading');
            openGallery(window.gallery, items)
                .finally(() => element.classList.remove('is-loading'));
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.i-image-gallery-popup')].forEach((element) => {
        initialiseImageGallery(element);
    });
});
