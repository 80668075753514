import '~@componentJs/faq';
import scroller from '~@helpers/scroller';
import lazyLoadImages from '~@helpers/lazy-load-images';
import Readmore from '../components/read-more';
import './trade-landing';
import './returns-policy';
import '~@componentJs/carousel';

[...document.querySelectorAll('.i-read-more')].forEach(element => new Readmore(element));
[...document.querySelectorAll('[data-scroll-to]')].forEach((elm) => elm.addEventListener('click', scroller));
lazyLoadImages();
